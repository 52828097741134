<div class="flex justify-center w-full">
  <div
    class="bg-white shadow-md rounded-lg px-6 py-8 w-11/12 md:1/2 max-w-lg md:p-10 flex flex-col items-center relative">
    @if (inDrawer()) {
      <button type="button"
              (click)="drawerService.close(false)"
              class="absolute top-1 right-1 inline-flex rounded-md p-1.5 text-gray-500 hover:text-100ciels-300 focus:outline-none focus:ring-1 focus:ring-100ciels-300 focus:ring-offset-2 focus:ring-offset-100ciels-100">
        <span class="sr-only">Dismiss</span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
        </svg>
      </button>
    }
    <h1 class="text-md lg:text-2xl font-bold uppercase text-gray-500">
      {{ 'signInOrRegister' | translate }}
    </h1>
    <p
      class="font-medium text-gray-500 text-sm lg:text-md mt-2 mb-3 lg:mb-0">{{ 'enterInto100Ciels' | translate }}
    </p>

    <form [formGroup]="form" class="w-full flex-1 mt-0 md:mt-4 flex flex-col items-center">
      <div class="w-full">
        <p class="font-medium text-gray-500 text-sm mt-3">{{ 'email' | translate }}</p>

        <input
          class="mt-2 w-full py-4 rounded-lg border-gray-200 font-medium bg-white text-sm focus:outline-none"
          formControlName="email"
          type="email"/>
        <p class="font-medium text-gray-500 text-sm mt-5">{{ 'password' | translate }}</p>
        <div class="relative mt-2 w-full flex">
          <input
            [type]="showPassword() ? 'text' : 'password'"
            class="grow py-4 rounded-l-lg border-gray-200 font-medium bg-white text-sm focus:outline-none"
            formControlName="password"/>
          <button (click)="showPassword.set(!showPassword())"
                  class="px-4 py-4 rounded-r-lg border border-l-0 border-gray-200 focus:outline-none"
                  type="button">
            <svg class="flex-shrink-0 size-5 text-gray-500" fill="none" height="24"
                 stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                 viewBox="0 0 24 24"
                 width="24">
              @if (showPassword()) {
                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"/>
                <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"/>
                <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"/>
                <line x1="2" x2="22" y1="2" y2="22"/>
              } @else {
                <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/>
                <circle cx="12" cy="12" r="3"/>
              }
            </svg>
          </button>
        </div>
        @if (form.controls.password.errors?.['required'] || form.controls.password.errors?.['pattern']) {
          <p class="peer-invalid:visible text-red-600 text-xs"
             [innerHtml]="'passwordRulesAndResetNotice' | translate | byPass">
          </p>
        }

        <div class="md:flex justify-end mt-4">
          <p class="text-xs text-gray-600 mt-4 md:mt-0">
            <button (click)="resetPassword()"
                    class="border-b border-gray-500 border-solid font-semibold"
                    type="button">
              {{ 'forgotPassword' | translate }}
            </button>
          </p>
        </div>
        <button (keyup.enter)="signIn()"
                [appProgressClick]="signIn"
                [disabled]="form.invalid"
                appHoverGradient
                class="big-btn py-4 text-md"
                type="submit">
          {{ 'signIn' | translate }}
        </button>
        <p class="mt-6 text-xs text-gray-600">
          {{ 'noAccount' | translate }}
          <a (click)="drawerService.close()" class="border-b border-gray-500 border-solid font-semibold"
             routerLink="/account/new">
            {{ 'createAccount' | translate }}
          </a>
        </p>
      </div>
      <div class="w-full mb-4 mt-0 md:mb-8 md:mt-4 border-b text-center">
        <div
          class="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-3/4">
          {{ 'or' | translate }}
        </div>
      </div>
      <div class="w-full flex flex-row gap-4 md:flex-col items-center">
        <button
          (click)="signInWithIdp('Google')"
          (keyup.enter)="emulateClick($event)"
          class="hover:bg-beige hover:border-beige w-full font-semibold border-gray-400 border rounded-lg py-4 text-gray-500 flex justify-evenly items-center px-4 md:px-10 transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
          type="button">

          <svg class="w-6" viewBox="0 0 533.5 544.3">
            <path
              d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
              fill="#4285f4"/>
            <path
              d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
              fill="#34a853"/>
            <path
              d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
              fill="#fbbc04"/>
            <path
              d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
              fill="#ea4335"/>
          </svg>

          <span class="hidden md:block ml-4 grow">{{ 'signInWithGoogle' | translate }}</span>
        </button>


        <button
          (click)="signInWithIdp('Facebook')"
          (keyup.enter)="emulateClick($event)"
          class="hover:bg-beige hover:border-beige w-full font-semibold border-gray-400 border rounded-lg py-4 text-gray-500 flex justify-evenly items-center px-4 md:px-10 transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
          type="button">

          <svg class="w-6"
               viewBox="73 0 267 266.9" x="0px"
               y="0px">
            <path d="M321.1,262.3c7.9,0,14.2-6.4,14.2-14.2V18.8c0-7.9-6.4-14.2-14.2-14.2H91.8
	C84,4.6,77.6,11,77.6,18.8v229.3c0,7.9,6.4,14.2,14.2,14.2H321.1z" fill="#157DC3"/>
            <path d="M255.4,262.3v-99.8h33.5l5-38.9h-38.5V98.8c0-11.3,3.1-18.9,19.3-18.9l20.6,0V45
	c-3.6-0.5-15.8-1.5-30-1.5c-29.7,0-50,18.1-50,51.4v28.7h-33.6v38.9h33.6v99.8H255.4z" fill="#FFFFFF" id="f"/>
          </svg>

          <span class="hidden md:block ml-4 grow">{{ 'signInWithFacebook' | translate }}</span>
        </button>
        @if (false) {
          <button
            type="button"
            (click)="signInWithIdp('Apple')"
            (keyup.enter)="emulateClick($event)"
            class="hover:bg-beige hover:border-beige py-4 w-full font-semibold border-gray-400 border rounded-lg text-gray-500 flex justify-evenly items-center px-4 md:px-10 transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
            <svg class="w-7"
                 viewBox="0 0 22.773 22.773">
              <g>
                <g>
                  <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573
              c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z"/>
                  <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334
              c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0
              c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019
              c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464
              c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648
              c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z"/>
                </g>
              </g>
            </svg>

            <span class="hidden md:block ml-4 grow">{{ 'signInWithApple' | translate }}</span>
          </button>
        }
      </div>
    </form>
  </div>
</div>
